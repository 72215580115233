import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button,ModalHeader,ModalBody } from "reactstrap";
import { ModalFooter } from "react-bootstrap";
import JobDetails  from '../careers/JobDetails.json'

import JS from '../../../assets/img/technologies/frontend/JavaScript.svg'
import Adnroid from '../../../assets/img/technologies/frontend/Adnroid.svg'
import AngularJS from '../../../assets/img/technologies/frontend/AngularJS.svg'
import flutter from '../../../assets/img/technologies/frontend/flutter.svg'
import iOS from '../../../assets/img/technologies/frontend/iOS.svg'
import ReactICON from '../../../assets/img/technologies/frontend/React.svg'
import ReactNative from '../../../assets/img/technologies/frontend/ReactNative.svg'
import TypeScript from '../../../assets/img/technologies/frontend/TypeScript.svg'
import Wordpress from '../../../assets/img/technologies/frontend/Wordpress.svg'
import laravel from '../../../assets/img/technologies/backend/laravel.svg'
import DotNet from '../../../assets/img/technologies/backend/microsoft-dot-net-icon.webp'
import nodejs from '../../../assets/img/technologies/backend/nodejs.svg'
import php from '../../../assets/img/technologies/backend/php.svg'
import Firebase from '../../../assets/img/technologies/database/Firebase.svg'
import Firestore from '../../../assets/img/technologies/database/Firestore.svg'
import MySQL from '../../../assets/img/technologies/database/MySQL.svg'
import MsSQL from '../../../assets/img/technologies/database/Mssql-server-icon.webp'
import PostageSQL from '../../../assets/img/technologies/database/PostageSQL.svg'
import Redis from '../../../assets/img/technologies/database/Redis.svg'
import AWSICON from '../../../assets/img/technologies/cloud/AWS.svg'
import cloudways from '../../../assets/img/technologies/cloud/cloudways.svg'
import digitalOcean from '../../../assets/img/technologies/cloud/digital-ocean.svg'
import GoogleCloud from '../../../assets/img/technologies/cloud/Google-cloud.svg'
import heroku from '../../../assets/img/technologies/cloud/heroku.svg'
import nextjs from '../../../assets/img/technologies/frontend/nextjs.svg'
import CodeIgniter from '../../../assets/img/technologies/backend/CodeIgniter.svg'

const icons = [
    { icon: flutter, title: 'Flutter' },
    { icon: ReactICON, title: 'React' },
    { icon: Wordpress, title: 'Wordpress' },
    { icon: iOS, title: 'iOS' },
    { icon: Adnroid, title: 'Adnroid' },
    { icon: JS, title: 'JS' },
    { icon: AngularJS, title: 'AngularJS' },
    { icon: ReactNative, title: 'React Native' },
    { icon: TypeScript, title: 'Type Script' },
    { icon: php, title: 'PHP' },
    { icon: nodejs, title: 'Node Js' },
    { icon: laravel, title: 'Laravel' },
    { icon: DotNet, title: '.Net' },
    { icon: MySQL, title: 'MySQL' },
    { icon: PostageSQL, title: 'Postage SQL' },
    { icon: MsSQL, title: 'MsSQL' },
    { icon: Firebase, title: 'Firebase' },
    { icon: Firestore, title: 'Firestore' },
    { icon: Redis, title: 'Redis' },
    { icon: AWSICON, title: 'AWS' },
    { icon: cloudways, title: 'Cloudways' },
    { icon: digitalOcean, title: 'Digital Ocean' },
    { icon: GoogleCloud, title: 'Google Cloud' },
    { icon: heroku, title: 'Heroku' },
    { icon: nextjs, title: 'nextjs' },
    { icon: CodeIgniter, title: 'CodeIgniter' },
    
]

function Joblist(props) {
  const [isOpen, setisOpen] = useState(false);
  const [jobTitle, setjobTitle] = useState();
  const redirectToJobDetails = () => {
    window.location.href = "/job-application/"+setjobTitle;
  };
  return (
    <section className="job-list-section section-gap">
      <div className="container">
        <div className="section-title text-center both-border mb-50">
          <span className="title-tag">Be a Part of Our Team</span>
          <h2 className="title">Current Openings</h2>
        </div>
        <div className="job-lists">
          {JobDetails.map((item, i) => 
          {
            if(item.display == "true")
              return (
                <div key={i} className="single-job grey-bg">
                  <h4 className="title mb-2">
                    {item.JobTitle}{" "}
                    <span className="job-time">({item.jobtime})</span>
                  </h4>
                  <h5 className="title experience mb-3">
                    Experience : <strong>{item.Experience}</strong> |  No. of Opening : <strong>{item.Opening? item.Opening: 1}</strong>
                  </h5>
                  <p>{item.text}</p>
                  <div className="row align-items-center">
                    <div className="col-md-7 col-12 mt-4">
                      <div className="row">
                        <div className="job-categories front-end-technology front-end-technology-career">
                          {item.icon.map((itemImg, j) => (
                            <div
                              key={j}
                              className="single-cat wow fadeInUp"
                              data-wow-duration="1500ms"
                              data-wow-delay="1.1s"
                            >
                              <span>
                                <img
                                  className="img img-fluid"
                                  src={icons.find(icon => icon.title === itemImg).icon}
                                  alt={icons.find(icon => icon.title === itemImg).title}
                                  width={85}
                                  height={85}
                                />
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-12 text-end">
                      <a
                        href="javascript:void(0);"
                        className="apply-btn"
                        onClick={() => {
                          setjobTitle(item.slug);
                          window.location.href = "/job-application/"+item.slug;
                        }}
                      >
                        {item.btntext} <i className="fa fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </div>
        {/* <div className="view-btn text-center mt-60">
          <Link to="/service" className="main-btn">
            View All Jobs
          </Link>
        </div> */}
      </div>
    </section>
  );
}

export default Joblist;
